import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Table } from "semantic-ui-react";
import { primaryColors } from "../styles/colours";
import { StyledPageCopy, StyledPageSubHeading } from "./styles";

const GetWorkReady = () => {
  const data = useStaticQuery(graphql`
    query getWorkReadyPageQuery {
      allGoogleSheet {
        edges {
          node {
            getWorkReady {
              title
              subTitle
              copy
              date
              school
              year
            }
            eventList {
              date
              eventType
              school
              year
            }
          }
        }
      }
    }
  `);

  const dataGroup = data?.allGoogleSheet?.edges?.[0]?.node;
  const pageData = dataGroup?.getWorkReady;
  const eventList = dataGroup?.eventList
    ?.filter(({ eventType }) => eventType === "getWorkReady")
    .sort(function(a, b) {
      return new Date(a.date) - new Date(b.date);
    });

  return (
    <div>
      <StyledPageSubHeading>{pageData?.[0]?.title}</StyledPageSubHeading>
      <StyledPageCopy
        color={primaryColors.violet}
        dangerouslySetInnerHTML={{ __html: pageData?.[0]?.subTitle }}
      />
      <StyledPageCopy
        dangerouslySetInnerHTML={{ __html: pageData?.[0]?.copy }}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>School or Educational Setting</Table.HeaderCell>
            <Table.HeaderCell>Year Group/s</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {eventList?.map((data, i) => {
            return (
              <Table.Row>
                <Table.Cell>{data?.date}</Table.Cell>
                <Table.Cell>{data?.school}</Table.Cell>
                <Table.Cell>{data?.year}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default GetWorkReady;
