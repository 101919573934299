import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Table } from "semantic-ui-react";
import { Spacer } from "ui-start";
import { primaryColors } from "../styles/colours";
import { StyledPageCopy, StyledPageSubHeading } from "./styles";

const ThemedDays = () => {
  const data = useStaticQuery(graphql`
    query themedDaysPageQuery {
      allGoogleSheet {
        edges {
          node {
            themedDays {
              title
              copy
              date
              school
              year
            }
            eventList {
              date
              eventType
              school
              year
            }
          }
        }
      }
    }
  `);

  const dataGroup = data?.allGoogleSheet?.edges?.[0]?.node;
  const pageData = dataGroup?.ThemedDays;
  const eventList = dataGroup?.eventList
    ?.filter(({ eventType }) => eventType === "themedDays")
    .sort(function(a, b) {
      return new Date(a.date) - new Date(b.date);
    });

  return (
    <div>
      <StyledPageSubHeading>ELSA THEMED DAYS</StyledPageSubHeading>
      {pageData?.map((data, i) => (
        <div key={i}>
          <Spacer height={32} />
          <StyledPageSubHeading>
            <span
              style={{
                fontSize: "18px",
              }}
            >
              {data?.title}
            </span>
          </StyledPageSubHeading>
          <StyledPageCopy dangerouslySetInnerHTML={{ __html: data?.copy }} />
        </div>
      ))}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>School or Educational Setting</Table.HeaderCell>
            <Table.HeaderCell>Year Group/s</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {eventList?.map((data, i) => {
            if (data?.date) {
              return (
                <Table.Row>
                  <Table.Cell>{data?.date}</Table.Cell>
                  <Table.Cell>{data?.school}</Table.Cell>
                  <Table.Cell>{data?.year}</Table.Cell>
                </Table.Row>
              );
            }
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ThemedDays;
