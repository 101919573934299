import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Spacer } from "ui-start";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import GetWorkReady from "../components/getWorkReady";
import GetWorkReadyMini from "../components/getWorkReadyMini";
import Layout from "../components/layout";
import { Wrapper } from "../components/styles";
import ThemedDays from "../components/themedDays";
import { HeroImage } from "../components/heroImage";

const Events = () => {
  const data = useStaticQuery(graphql`
    query eventsPageQuery {
      allGoogleSheet {
        edges {
          node {
            events {
              title
              heroImage
            }
          }
        }
      }
    }
  `);

  const pageData = data?.allGoogleSheet?.edges?.[0]?.node?.events?.[0];

  return (
    <Layout>
      <HeroImage page={'events'} />
      <Wrapper>
        <Spacer height={32} />
        <GetWorkReadyMini />
        <Spacer height={40} />
        <hr />
        <Spacer height={32} />
        <GetWorkReady />
        <Spacer height={40} />
        <hr />
        <Spacer height={32} />
        <ThemedDays />
        <Spacer height={32} />
      </Wrapper>
    </Layout>
  );
};

export default Events;
